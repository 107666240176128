<template>
  <div class="section">
    <navigation></navigation>
    <h2 class="mt-8">This is the about page.</h2>
  </div>
</template>

<script>
import Navigation from './Navigation.vue'
export default {
  components: { Navigation },

}
</script>

<style>
  .section{
    padding: 5px 65px 5px 65px;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
      .section{
        padding: 0px;
      }
    }
</style>
